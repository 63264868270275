<template>
  <div class="p_20">
    <!-- <div class="flex justify_content_between">
      <el-button size="small" type="primary"  v-if="isRole(['Role/addRole'])"  @click="add">创建权限</el-button>

    </div> -->
    <div
      class="flex justify_content_between align_items_center content-wrapper p_lr_20 m_b_20"
    >
      <div class="flex align_items_center">
        <h2>权限管理</h2>
        <div class="p_l_20">
          <el-button
            size="small"
            v-if="isRole(['Authority/addRole'])"
            type="primary"
            @click="add"
            >创建权限</el-button
          >
        </div>
      </div>
    </div>

    <!-- <el-divider></el-divider> -->

    <!-- -项目列表- -->
    <div class="content-wrapper p_20">
      <el-table :data="tableData" width="100%">
        <el-table-column label="创建时间" prop="created_at">
          <template slot-scope="scope">
            {{ scope.row.created_at | format }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="类型" prop="name"></el-table-column> -->
        <el-table-column label="权限名" prop="name"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="edit(scope.row)"
               v-if="isRole(['Authority/editorRole'])"
              >编辑</el-button
            >
            <el-button
              size="small"
               v-if="isRole(['Authority/deleteRole'])"
              @click="remove(scope.row)"
              >删除</el-button
            >
            
              <!--
              v-if="isRole(['Role/modifyRole'])" v-if="isRole(['Role/deleteRole'])" -->
          </template>
        </el-table-column>
      </el-table>
      <page ref="Page" :total="total"></page>
      <Edit ref="Edit"></Edit>
    </div>
  </div>
</template>
<script type="text/javascript">
import methods from "./method";
import Edit from "./edit/edit.vue";

export default {
  data() {
    return {
      tableData: [],
      total: "",
    };
  },
  methods: methods,
  mounted() {
    this.getTableData();
  },
  components: {
    Edit,
  },
};
</script>
