<template>
  <div class="p_20 indexBanner">
    <el-dialog
      :visible.sync="isShow"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <!-- <el-divider></el-divider> -->
      <el-form :model="form" label-width="80px">
        <el-form-item label="权限名">
          <el-input v-model="form.name" class="w_400"></el-input>
        </el-form-item>
        <!-- <el-form-item label="账户类型">
					<el-select v-model="form.account_type_id" @change="getClient">
						<el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item> -->
        <el-form-item label="备注">
          <el-input
            class="w_400"
            type="textarea"
            :rows="5"
            v-model="form.remark"
          ></el-input>
        </el-form-item>

        <el-form-item label="权限" v-if="form.id">
          <div class="p_20">
            <el-tree
              class="filter-tree"
              :data="opt1"
              :props="defaultProps"
              node-key="id"
              show-checkbox
              @check="handleCheckChange"
              ref="tree"
            >
            </el-tree>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      opt: [],
      opt1: [],
      form: {
        id: "",
        name: "",
        remark: "",
        // account_type_id:'',
        // client_id:''
      },
      role: "",
      time: "",
      defaultProps: {
        children: "child",
        label: "alias",
        id: "id",
      },
      //图片上传
      imageUrl: "",
      // dialogVisible: false,
      // disabled: false
    };
  },
  mounted() {
    // this.getType();
    this.getRoleTree();
  },
  methods: {

    //客户类型
    getType() {
      this.axiosGets("Public/accountTypeList", {}, (res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.opt = res.data.data;
        }
      });
    },
  //权限树
    getRoleTree() {
      this.axiosPost("Authority/getPermissionTree", {}, (res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.opt1 = res.data.data;
        }
      });
    },
    //要添加的权限
    handleCheckChange(res, check) {
      this.role = check.checkedNodes;
    },

    //添加权限给角色
    giveRole() {
      let ids = [];
      this.role.forEach((item) => {
        if (item.parent_id != 0) {
          ids.push(item.id);
        }
      })
      console.log(ids);

      this.axiosPost("Authority/syncPermissions", {
        role_id: this.form.id,
        permission_ids: ids
      }, function(data) {
        if (data.data.code == 200) {
          this.msgSuccess(data.data.message);
          // this.history("/admin/list")
          this.closeDialog();
          this.$options.parent.getTableData();
        } else {
          this.msgError(data.data.message)
        }
      })
    },

    //回填角色所选的权限 
    showRole(){
      let params = {
        role_id:this.form.id
      }
      this.axiosPost('Authority/roleHasPermissions' , params , res => {
        console.log(res);
        if(res.data.code == 200) {
          this.role = res.data.data;
          this.$refs.tree.setCheckedKeys(this.role)
        }
      })
    },


    //提交
    submit() {
      console.log(this.form);
      let params = this.form;

      if (this.form.id) {
        this.edit(params);
      } else {
        this.add(params);
      }
    },

    //新增
    add(params) {
      this.axiosPost("Authority/addRole", params, (res) => {
        if (res.data.code == 200) {
          this.msgSuccess(res.data.message);
          this.$parent.getTableData();
          this.isShow = false;
        }
      });
    },

    //编辑
    edit(params) {
      this.axiosPost("Authority/editorRole", params, (res) => {
        if (res.data.code == 200) {
          this.giveRole();
          // this.msgSuccess(res.data.message);
          this.$parent.getTableData();
          this.isShow = false;
        }
      });
    },

    //关闭窗口 清空数据
    closeDialog() {
      (this.form = {
        id: "",
        name: "",
        remark: "",
      }),
        (this.isShow = false);
    },
  },
};
</script>

<style scoped>
</style>
