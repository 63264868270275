export default {
	// 获取页面数据
	getTableData() {
		this.onLoad(true)
		var params = {
			// id:this.id

			page: this.$refs.Page.pagination.current,
			limit: this.$refs.Page.pagination.pageSize
		}

		this.axiosGets("Authority/roleList", params, function (res) {
			if (res.data.code === 200) {
				// this.msgSuccess(res.data.message)
		this.onLoad(false)
				this.tableData = res.data.data.data;
				this.total = res.data.data.total
			} else {
				this.msgError(res.data.message)
			}

		})
	},
	
	//添加
	add() {
		// this.history('/admin/edit')
		this.$refs.Edit.isShow = true;
	},

	//编辑
	edit(scope) {
		this.$refs.Edit.form = scope;
		this.$refs.Edit.showRole();
		// this.$refs.Edit.getRoleTree();
		// setTimeout(() => {
		// 	this.$refs.Edit.getRole();
		// },300)
		this.$refs.Edit.isShow = true;
	},

	//删除
	remove(scope) {
		this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {

			this.sureRemove(scope.id);
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},

	//确定删除
	sureRemove(id) {

		this.axiosGets("Authority/deleteRole?id=" + id, {}, function(res) {
			if (res.data.code === 200) {
				this.getTableData();
				this.msgSuccess(res.data.message)
			} else {
				this.msgError(res.data.message)
			}
		})
	}

}
